import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Hero from '../components/Shared/Hero'
import Search from '../components/Shared/Search'
import Personas from '../components/Shared/Personas'
import Space from '../components/Shared/Space'
import Event from '../components/Shared/Event'
import Push from '../components/Shared/Push'
import Discovery from '../components/Shared/Discovery'
import Lifestyle from '../components/Shared/Lifestyle'
import Instagram from '../components/Shared/Instagram'
import Twist from '../components/Shared/Twist'
import Partner from '../components/Shared/Partner'
import * as styles from './styles/home.module.scss'

import { graphql } from 'gatsby'

import DecoBigIllus from '../components/Illus/DecoBigIllus'
import { Helmet } from 'react-helmet'
import BlockSeoText from '../components/Shared/BlockSeoText'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'

export const Head = head('umbracoHomePage')

export default function Home(props: { data: any }) {
  const { data } = props

  const page = data.umbracoHomePage
  const instagramData = data.umbracoInstagram

  const pageTitle = page?.metaTitle
    ? page?.metaTitle
    : page?.title
    ? page?.title.replace(/\n/g, '')
    : 'Home'

  useEffect(() => {
    if (typeof window !== 'undefined' && window.document) {
      usePageView(pageTitle, window.location.pathname)
    }
  }, [])

  return (
    <>
      <Helmet>
        <body className="homepage" />
      </Helmet>
      <Layout showToTop className={styles.home}>
        <Hero
          title={page && page.title}
          image={page.imageCover}
          image-mobile={page.imageCoverMobile}
          video={page.videoCover}
          vimeo={page.vimeo}
          tag="div"
        >
          {/* <Search /> */}
          <DecoBigIllus className="deco" />
        </Hero>
        {page &&
          page.items &&
          page.items.map((homeBlock: any, index: number) => {
            if (homeBlock.content) {
              const blockContent = homeBlock.content
              switch (blockContent.contentTypeAlias) {
                case 'blockPersonas':
                  return (
                    <Personas
                      tag="h1"
                      data={{
                        title:
                          blockContent.title || 'Le coliving twiste pour vous',
                        description:
                          blockContent.subtitle ||
                          `Prenez le temps d’être en mouvement ! Twist by Kley, c’est le
                  nouveau lieu de vie idéal des jeunes actifs pour travailler,
                  découvrir, se recentrer, s'épanouir et rejoindre une véritable
                  communauté !`,
                        personas: blockContent.itemsPersona,
                        contentTypeAlias: blockContent.contentTypeAlias,
                      }}
                      key={`home-${index}`}
                    />
                  )
                case 'blockConcept':
                  return (
                    <Lifestyle
                      uniqId={`hs-${index}`}
                      data={blockContent}
                      reverse
                      withLink
                      key={`home-${index}`}
                      className={styles.homeLifeStyle}
                    />
                  )
                case 'blockConcept1':
                  return (
                    <Space
                      uniqId={`hc-${index}`}
                      data={blockContent}
                      className={styles.homeSpace}
                      key={`home-${index}`}
                    />
                  )
                case 'blockNewEvents':
                  return <Event data={blockContent} key={`home-${index}`} />
                case 'blockShowcaseImages':
                  return (
                    <Lifestyle
                      uniqId={`hs-${index}`}
                      data={blockContent}
                      type="slider"
                      withLink
                      key={`home-${index}`}
                      className={styles.homeLifeStyle}
                    />
                  )
                case 'blockResidence':
                  return <Discovery data={blockContent} key={`home-${index}`} />
                case 'blockPush':
                  return <Push data={blockContent} key={`home-${index}`} />
                case 'blockRSE':
                  return <Twist data={blockContent} key={`home-${index}`} />
                case 'blockPartners':
                  return <Partner data={blockContent} key={`home-${index}`} />
                default:
                  return
              }
            }

            return <></>
          })}

        <Instagram data={instagramData} />
        <div className="bg-white">
          <BlockSeoText content={page.bottomSEOText} />
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query MyQuery($lang: String) {
    umbracoHomePage(lang: { eq: $lang }) {
      id
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      bottomSEOText
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      _url
      _urls {
        fr
        en_us
      }
      items {
        content {
          title
          subtitle
          contentTypeAlias
          colorCombination
          mainColor
          cover {
            gatsbyImageData(layout: CONSTRAINED, width: 1480, quality: 90)
            media {
              alt
            }
          }
          illus {
            src
          }
          illustration {
            src
            gatsbyImageData(width: 400, quality: 90)
          }
          link {
            url
            type
            name
            target
          }
          linkEvent
          links {
            url
            name
          }
          virtualVisit
          virtualVisitUrl
          virtualVisitButtonLabel
          backgroundVimeoId
          slides {
            content {
              image {
                src
                media {
                  alt
                }
                # gatsbyImageData(layout: CONSTRAINED, width: 2560, quality: 90)
                gatsbyImageData
              }
              legend
            }
          }
          eventsList {
            title
            subtitle
            bg
            thumbnail {
              media {
                alt
                _url
              }
              gatsbyImageData(width: 880, quality: 90)
            }
            backgroundVimeoId
            category {
              color
              title
            }
            price
          }
          spaces {
            title
            subtitle
            name
            mainColor
            logo {
              src
            }
            medias {
              src
              gatsbyImageData(width: 880, quality: 90)
              media {
                alt
              }
            }
          }
          itemsPush {
            name
            title
            subtitle
            sortOrder
            titleColor
            backgroundColor
            contentTypeAlias
            link {
              url
              type
              name
            }
            linkEvent
            illustration {
              src
            }
            pushStyle
          }
          itemsPersona {
            name
            title
            subtitle
            frameColor
            contentTypeAlias
            link {
              url
              type
              name
            }
            thumbnail {
              gatsbyImageData(width: 180, quality: 90)
              media {
                alt
              }
            }
          }
          residences {
            title
            name
            shortName
            _url
            _urls {
              fr
              en_us
            }
            comingSoon
            imageCover {
              src
              media {
                alt
              }
              gatsbyImageData(width: 880, quality: 90)
            }
          }
          partnersList {
            content {
              title
              link {
                url
                type
                name
              }
              logo {
                src
                gatsbyImageData(width: 660, quality: 90)
                media {
                  alt
                }
              }
            }
          }
        }
      }
      imageCover {
        childImageSharp {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imageCoverMobile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoCover {
        src
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      vimeo
      title
    }
    umbracoInstagram(lang: { eq: $lang }) {
      title
      hashtag
      instagramHandle
      instagramLinkButton {
        url
        name
        type
      }
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
